/**
 * Theme global.js
 */

function init() {
    'use strict';

    //Initialize Animate on scroll script
    AOS.init();
}

document.addEventListener('DOMContentLoaded', init);

jQuery(window).load(function () {

    //Accordion to FAQ-page. In case of emergency pls check accordion.js also.
    var accordions = document.querySelectorAll('.accordion-list');
    if (accordions.length) {
        for (var i = 0; i < accordions.length; i++) {
            accordions[i].accordion('.accordion-head', '.accordion-item');
        }
    }
});


jQuery(document).ready(function () {
    jQuery('.post-image-block h2').finnishHyphenator();
});
